import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import fetch from '../../utils/fetch';
import { CLEAR, LOADING, LOGIN } from './constants';

export function useAuthorization() {
  const dispatch = useDispatch();
  const history = useHistory();
  return {
    LoginAction: async (payload) => {
      dispatch({ loading: true, type: LOADING });
      try {
        const data = await fetch.post(`${process.env.REACT_APP_API}/user/login`, payload );
        dispatch({ loading: false, type: LOADING });
        dispatch({ errorMessage: '', profile: data.data.data.profile, token: data.data.data.token, type: LOGIN });
        localStorage.setItem('token', data.data.data.token.token);
        localStorage.setItem('refresh_token', data.data.data.token.refresh_token);
        localStorage.setItem('auth', 'true');
        history.push('/dashboard');
      } catch (error) {
        dispatch({ loading: false, type: LOADING });
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed login, please contact team', type: LOGIN });
      }
    },
    Logout: () => {
      localStorage.clear();
      dispatch({
        payload: {
          errorMessage: '',
          loading: false,
          profile: {
            email: '',
            phone: '',
            status: '',
            username: ''
          },
          token: {
            refresh_token: '',
            refresh_token_expires_in: '',
            token: '',
            token_expires_in: ''
          }
        },
        type: CLEAR,
      });
      history.push('/login');
    },
  };
}
