import React, { lazy, Suspense } from 'react';
import { LazyFallback } from '../components/elements';

const Suspensed = (Element) => function suspense(props) {
  return (
    <Suspense fallback={<LazyFallback />}>
      <Element {...props} />
    </Suspense>
  );
};

export default {
  Admin: Suspensed(lazy(() => import('./Admin'))),
  Banner: Suspensed(lazy(() => import('./Banner'))),
  Category: Suspensed(lazy(() => import('./Category'))),
  Customer: Suspensed(lazy(() => import('./Customer'))),
  Dashboard: Suspensed(lazy(() => import('./Dashboard'))),
  Driver: Suspensed(lazy(() => import('./Driver'))),
  Error: Suspensed(lazy(() => import('./Error'))),
  Error404: Suspensed(lazy(() => import('./Error404'))),
  Finish: Suspensed(lazy(() => import('./Finish'))),
  Hub: Suspensed(lazy(() => import('./Hub'))),
  Inbound: Suspensed(lazy(() => import('./Inbound'))),
  Inventory: Suspensed(lazy(() => import('./Inventory'))),
  InvetoryAdjustment: Suspensed(lazy(() => import('./InventoryAdjustment'))),
  Login: Suspensed(lazy(() => import('./Auth/Login'))),
  Order: Suspensed(lazy(() => import('./Order'))),
  Outbound: Suspensed(lazy(() => import('./Outbound'))),
  Pending: Suspensed(lazy(() => import('./Pending'))),
  Product: Suspensed(lazy(() => import('./Product'))),
  Shipment: Suspensed(lazy(() => import('./Shipment'))),
  SpecialHour: Suspensed(lazy(() => import('./SpecialHour'))),
  StandardHour: Suspensed(lazy(() => import('./StandardHour'))),
  Supplier: Suspensed(lazy(() => import('./Supplier'))),
  Transaction: Suspensed(lazy(() => import('./Transaction'))),
  Transfer: Suspensed(lazy(() => import('./Transfer'))),
};
