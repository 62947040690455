import { SET_ALERT } from './constants';

const timeout = [];
export function setAlert(payload) {
  return (dispatch) => {
    dispatch(viewAlert(payload));
    if (payload.alert !== 'hide') {
      timeout.forEach((v) => clearTimeout(v));
      const tm = window.setTimeout(() => {
        dispatch(viewAlert({ message: '', type: 'hide' }));
      }, 3000);
      timeout.push(tm);
    } else {
      dispatch(viewAlert(payload));
    }
  };
}

function viewAlert(payload) {
  return { payload, type: SET_ALERT };
}
