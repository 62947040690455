import React from 'react';
import Button from '../Button';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const Pagination = (props) => {
  return (
    <div className={['d-flex justify-content-between align-items-center', styles['pagination']].join(' ')}>
      <p className={styles['pagination-text']}>Showing {props.page}-{props.totalPage} of {props.totalData} result</p>
      {props.totalData > 10 && (
        <div className="d-flex">
          <Button children="Previous" className={['me-4', styles['btn-pagination']].join(' ')} isDisabled={props.page === 1} onClick={props.onPrevious} />
          <Button children="Next" className={styles['btn-pagination']} isDisabled={props.page === props.totalPage} onClick={props.onNext} />
        </div>
      )}
    </div>
  );
};

export default Pagination;

Pagination.defaultProps = {
  hasNext: false,
  onNext: null,
  onPrevious: null,
  page: 1,
  totalData: 1,
  totalPage: 1
};

Pagination.propTypes = {
  hasNext: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  page: PropTypes.number,
  totalData: PropTypes.number,
  totalPage: PropTypes.number
};
