import { combineReducers } from 'redux';
import DashboardReducers from '../pages/Dashboard/reducer';
import AuthReducers from '../pages/Auth/reducer';
import ProductReducers from '../pages/Product/reducer';
import InboundReducers from '../pages/Inbound/reducer';
import OutboundReducers from '../pages/Outbound/reducer';
import InventoryReducers from '../pages/Inventory/reducer';
import SupplierReducers from '../pages/Supplier/reducer';
import HubReducers from '../pages/Hub/reducer';
import CategoryReducers from '../pages/Category/reducer';
import AlertReducers from '../components/elements/Alert/reducer';
import BannerReducers from '../pages/Banner/reducer';
import OrderReducers from '../pages/Order/reducer';
import TransactionReducers from '../pages/Transaction/reducer';
import ShipmentReducers from '../pages/Shipment/reducer';
import PagesReducers from '../pages/reducer';
import SpecialHoursReducers from '../pages/SpecialHour/reducer';
import StandardHoursReducers from '../pages/StandardHour/reducer';

const rootReducer = combineReducers({
  alert: AlertReducers,
  auth: AuthReducers,
  banner: BannerReducers,
  category: CategoryReducers,
  dashboard: DashboardReducers,
  hub: HubReducers,
  inbound: InboundReducers,
  inventory: InventoryReducers,
  order: OrderReducers,
  outbound: OutboundReducers,
  pages: PagesReducers,
  product: ProductReducers,
  shipment: ShipmentReducers,
  specialHours: SpecialHoursReducers,
  standardHours: StandardHoursReducers,
  supplier: SupplierReducers,
  transaction: TransactionReducers,
});

export default rootReducer;
