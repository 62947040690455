import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { ICONS } from '../../../configs';

const Alert = (props) => {
  const { type, message, onClose } = props;

  const _renderClassTypes = () => {
    switch (type) {
      case 'success':
        return styles.success;
      case 'danger':
        return styles.danger;
      default:
        return styles.success;
    }
  };

  const _renderBodyAlert = () => {
    if (type === 'success') {
      return (
        <>
          <div className={styles.flex_center}>
            <ICONS.SUCCESS className={styles.icon} />
            <span>{message}</span>
          </div>
          <ICONS.CLOSE className={styles['icon-close']} onClick={onClose} />
        </>
      );
    } else {
      return (
        <>
          <div className={styles.flex_center}>
            <ICONS.ERROR className={styles.icon} />
            <span>{message}</span>
          </div>
          <ICONS.CLOSE className={styles['icon-close']} onClick={onClose} />
        </>
      );
    }
  };

  return (
    <div className={styles['alert-container']}>
      <div className={[styles['alert-content'], _renderClassTypes()].join(' ')}>{_renderBodyAlert()}</div>
    </div>
  );
};

export default Alert;

Alert.defaultProps = {
  onClose: null,
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string.isRequired
};
