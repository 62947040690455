export const numberFormat = num => {
  return new Intl.NumberFormat('id-ID').format(num);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const toRupiah = (num, prefix, useEndPref) => {
  let reverse = num.toString().split('').reverse().join(''),
    ribuan = reverse.match(/\d{1,3}/g),
    res = ribuan.join('.').split('').reverse().join('');
  return (prefix ? (prefix + res).toString() : res?.toString()) + (useEndPref ? ',-' : '');
};

export const previewFile = (file, callback) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(file);
};

export const isLatitude = num => isFinite(num) && Math.abs(num) <= 90;

export const isLongitude = num => isFinite(num) && Math.abs(num) <= 180;

export const base64 = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise(res => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      res(reader.result);
    });
    reader.readAsDataURL(blob);
  });
};

export const previousSlide = (images, currImage) => {
  const lastIndex = images.length - 1;
  const shouldResetIndex = currImage === 0;
  const index = shouldResetIndex ? lastIndex : currImage - 1;

  return index;
};

export const nextSlide = (images, currImage) => {
  const lastIndex = images.length - 1;
  const shouldResetIndex = currImage === lastIndex;
  const index = shouldResetIndex ? 0 : currImage + 1;

  return index;
};

export const capitalizeFirstLetter = (string) => {
  return string.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};
