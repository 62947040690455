export const FETCH_ORDER = 'FETCH_ORDER';
export const ORDER_ID = 'ORDER_ID';
import { format, parseISO } from 'date-fns';
import { capitalizeFirstLetter, toRupiah } from '../../utils/others';

export const DATA_FETCHED = 'DATA_FETCHED';
export const GET_USER = 'GET_USER';
export const contentReceipt = (orderId) => {
  return {
    content: [
      { alignment: 'center', height: 50, image: 'data', width: 142 },
      {
        alignment: 'center',
        style: 'date',
        text: format(parseISO(orderId.receipt_user.created_at), 'dd LLL yyyy, hh:mm'),
      },
      {
        columns: [
          {
            style: 'label',
            text: 'Order ID',
            width: '40%',
          },
          {
            alignment: 'left',
            style: 'label',
            text: `: ${orderId.receipt_user.invoice_number}`,
            width: '60%',
          }
        ],
      },
      {
        columns: [
          {
            style: 'label',
            text: 'Customer',
            width: '40%',
          },
          {
            alignment: 'left',
            style: 'label',
            text: `: ${capitalizeFirstLetter(orderId.receipt_user.full_name)}`,
            width: '60%',
          }
        ],
      },
      {
        columns: [
          {
            style: 'label',
            text: 'Nomor HP',
            width: '40%',
          },
          {
            alignment: 'left',
            style: 'label',
            text: `: ${orderId.receipt_user.phone}`,
            width: '60%',
          }
        ],
      },
      {
        alignment: 'center',
        style: 'header',
        text: 'Order Receipt',
      },
      {
        layout: {
          hLineWidth: function (i) {
            return (i === 0 || i === 1) ? 1 : 0;
          },
          vLineWidth: function () {
            return 0;
          }
        },
        style: 'tableExample',
        table: {
          body: [],
        }
      },
      {
        canvas: [{
          lineColor: 'black',
          lineWidth: 1,
          type: 'line',
          x1: 0,
          x2: 158,
          y1: 0,
          y2: 0,
        }]
      },
      {
        columns: [
          {
            margin: [5, 10, 5, 5],
            style: 'label',
            text: 'Subtotal',
            width: '50%',
          },
          {
            alignment: 'right',
            margin: [5, 10, 5, 5],
            style: 'label',
            text: `Rp${orderId.receipt_total.subtotal ? toRupiah(orderId.receipt_total.subtotal) : 0}`,
            width: '50%',
          }
        ],
      },
      {
        columns: [
          {
            style: 'label',
            text: 'Promo',
            width: '50%',
          },
          {
            alignment: 'right',
            style: 'label',
            text: `Rp${orderId.receipt_total.promo ? toRupiah(orderId.receipt_total.promo) : 0}`,
            width: '50%',
          }
        ],
      },
      {
        columnGap: 10,
        columns: [
          {
            style: 'label',
            text: 'Ongkos Kirim',
            width: '50%',
          },
          {
            alignment: 'right',
            style: 'label',
            text: `Rp${orderId.receipt_total.shipment_fee ? toRupiah(orderId.receipt_total.shipment_fee) : 0}`,
            width: '50%',
          }
        ],
      },
      {
        columnGap: 10,
        columns: [
          {
            bold: true,
            margin: [5, 5, 5, 15],
            style: 'label',
            text: 'Total Pembayaran',
            width: '50%',
          },
          {
            alignment: 'right',
            margin: [5, 5, 5, 15],
            style: 'label',
            text: `Rp${orderId.receipt_total.total ? toRupiah(orderId.receipt_total.total) : 0}`,
            width: '50%',
          }
        ],
      },
      {
        canvas: [{
          lineColor: 'black',
          lineWidth: 1,
          type: 'line',
          x1: 0,
          x2: 158,
          y1: 0,
          y2: 0,
        }]
      },
    ],
    // defaultStyle: {
    //   font: 'Platform'
    // },
  };
};

export const contentLabel = (label) => {
  return {
    content: [
      {
        columnGap: 5,
        columns: [
          {
            alignment: 'left',
            height: 40,
            image: 'data',
            width: 115,
          },
          {
            alignment: 'right',
            margin: [0, 0, 10, 0],
            style: 'date',
            text: format(parseISO(label.created_at), 'dd LLL yyyy, hh:mm'),
            // width: '33%',
          }
        ],
      },
      {
        alignment: 'left',
        fontSize: 10,
        text: label.label_invoice_number,
      },
      {
        alignment: 'left',
        bold: true,
        fontSize: 12,
        margin: [0, 10, 0, 0],
        style: 'title',
        text: 'KEPADA',
      },
      {
        alignment: 'left',
        bold: true,
        fontSize: 18,
        margin: [0, 0, 0, 0],
        text: label.name_receiver,
      },
      {
        alignment: 'left',
        fontSize: 12,
        margin: [0, 0, 0, 10],
        text: label.phone_receiver,
      },
      {
        alignment: 'left',
        bold: true,
        fontSize: 12,
        margin: [0, 10, 0, 0],
        text: 'ALAMAT',
      },
      {
        alignment: 'left',
        fontSize: 8,
        margin: [0, 0, 0, 10],
        text: label.street,
      },
      {
        alignment: 'left',
        bold: true,
        color: 'black',
        fontSize: 12,
        margin: [0, 10, 0, 0],
        text: 'DETIL ALAMAT',
      },
      {
        alignment: 'left',
        fontSize: 8,
        margin: [0, 0, 0, 10],
        text: label.label,
      },
    ],
    // defaultStyle: {
    //   font: 'Platform'
    // },
  };
};
