import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import { SEARCH_GLOBAL } from '../../../pages/constants';

const SearchField = () => {
  const { searchData } = useSelector(state => state.pages);
  const history = useHistory();
  const dispatch = useDispatch();

  const _redirectPage = (router) => {
    if (router === 'Products') {
      history.push('/product');
    } else if (router === 'Categories') {
      history.push('category');
    } else {
      history.push('/dashboard');
    }
    dispatch({ payload: [], type: SEARCH_GLOBAL });
  };

  return (
    <div className={styles['search-field']}>
      {searchData.length > 0 ? (
        searchData.map((val, idx) => (
          <div className="d-flex justify-content-between align-items-center cursor-pointer" key={idx} onClick={() => _redirectPage(val.table)}>
            <div className="d-flex">
              <img alt="image search" src={val.image ? `${val.image.base_url}${val.image.url_link}` : ''} />
              <div>
                <h6>{val.name}</h6>
                <p>{val.sku ? val.sku : '-'}</p>
              </div>
            </div>
            <p>{val.table}</p>
          </div>
        ))
      ) : (
        <p className="text-center">Data not found</p>
      )}
    </div>
  );
};

export default SearchField;
