import { FETCH_TRANSACTION, LABEL_PACKAGE, TRANSACTION_ID } from './constants';

const initialState = {
  errorMessage: '',
  label: {
    created_at: '',
    invoice_number: '',
    label: '',
    label_invoice_number: '',
    name_receiver: '',
    phone_receiver: '',
    street: '',
  },
  successFetchId: false,
  transaction: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  transactionId: {
    receipt_products: [],
    receipt_total: {
      promo: 0,
      shipment_fee: 0,
      subtotal: 0,
      total: 0
    },
    receipt_user: {
      created_at: '',
      full_name: '',
      invoice_number: '',
      phone: '',
    },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSACTION:
      return {
        ...state,
        errorMessage: action.errorMessage,
        transaction: action.payload
      };
    case TRANSACTION_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        successFetchId: action.successFetchId,
        transactionId: action.payload
      };
    case LABEL_PACKAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        label: action.payload,
      };
    default:
      return state;
  }
}
