import React from 'react';
import { ICONS } from '../../../configs';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const List = ({ desc1, desc2, image, number, productName, total, type, numberClassName }) => {
  return (
    <div className="d-flex justify-content-between mb-3">
      <div className="d-flex">
        <div className={['text-center', styles['number'], numberClassName].join(' ')}>{number}</div>
        {image && <img alt="photo product" className={styles['photo-product']} src={image} />}
        <div className={styles['product']}>
          <h6>{productName}</h6>
          <p>{desc1} <ICONS.CIRCLESMALL className={styles['small-circle-icon']} /> {desc2}</p>
        </div>
      </div>
      <div className={['d-flex', styles['price']].join(' ')}>
        <h6>{total}</h6>
        {type === 'sale' && <p>Order</p>}
      </div>
    </div>
  );
};

export default List;

List.defaultProps = {
  desc1: '',
  desc2: '',
  image: '',
  number: 1,
  numberClassName: '',
  productName: '',
  total: 0,
  type: 'Sale',
};

List.propTypes = {
  desc1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  desc2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  image: PropTypes.string,
  number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberClassName: PropTypes.string,
  productName: PropTypes.string,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
};
