import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Tabs = ({ tabs, onChange }) => {
  const [tab, setTab] = useState('All');

  const _renderClassName = (idx, val) => {
    if (idx === 0 && tab === val) {
      return styles['nav-item-first'];
    } else if (tabs.length - 1 === idx && tab === val) {
      return styles['nav-item-last'];
    } else if (tab === val) {
      return styles['active'];
    } else {
      return styles['nav-item'];
    }
  };

  return (
    <ul className={[styles['nav'], styles['nav-pills']].join(' ')}>
      {tabs && tabs.length > 0 && tabs.map((val, idx) => {
        const label = val.label ? val.label : val;
        return (
          <li
            className={[_renderClassName(idx, label), 'cursor-pointer'].join(' ')}
            key={idx}
            onClick={() => {
              if (val.value) {
                onChange(val.value);
              }
            }}
          >
            <a
              className={[styles['nav-link'], tab === label && styles['active-text']].join(' ')}
              onClick={() => setTab(label)}
            >
              {label}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Tabs;

Tabs.defaultProps = {
  onChange: null,
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  tabs: PropTypes.array.isRequired
};
