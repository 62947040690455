import { LOADING } from '../Auth/constants';
import { ERROR_MESSAGE, FETCH_HUB, LOADING_BEGIN, HUB_ID, CRUD_HUB } from './constants';

const initialState = {
  errorMessage: '',
  hub: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  hubId: {
    created_at: '',
    created_by: '',
    edited_at: '',
    edited_by: '',
    id: 0,
    name: '',
  },
  loading: false,
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HUB:
      return {
        ...state,
        hub: action.payload,
        success: false,
      };
    case HUB_ID:
      return {
        ...state,
        hubId: action.payload
      };
    case LOADING:
    case LOADING_BEGIN:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CRUD_HUB:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success,
      };
    default:
      return state;
  }
}
