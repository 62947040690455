import React from 'react';
import styles from './styles.module.scss';

const LazyFallback = () => {
  return (
    <div className={['d-flex justify-content-center align-items-center', styles['lazy-fallback']].join(' ')}>
      <div className={['spinner-border', styles['size-spinner']].join(' ')} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LazyFallback;
