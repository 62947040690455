import { FETCH_SHIPMENT, SHIPMENT_ID, UPDATE_STATUS } from './constants';

const initialState = {
  errorMessage: '',
  errorUpdateStatus: '',
  loading: false,
  shipment: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  shipmentId: {
    receipt_shipment: {
      created_at: '',
      driver_name: '',
      full_name: '',
      invoice_number: '',
      phone: '',
      street: '',
    },
    receipt_shipment_tracking: []
  },
  successFetchId: false,
  successUpdate: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPMENT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        shipment: action.payload
      };
    case SHIPMENT_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        shipmentId: action.payload,
        successFetchId: action.successFetchId,
      };
    case UPDATE_STATUS:
      return {
        ...state,
        errorUpdateStatus: action.errorUpdateStatus,
        loading: action.loading,
        successUpdate: action.payload,
      };
    default:
      return state;
  }
}
