import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

const MenuSidebar = (props) => {
  const { toggle, title, router, icon, checkActive } = props;

  const _renderClassName = () => {
    if (toggle) {
      if (checkActive) {
        return styles['menu-item-toggle menu-item-active'];
      } else {
        return styles['menu-item-toggle'];
      }
    } else {
      if (checkActive) {
        return styles['menu-item-active'];
      } else {
        return styles['menu-item'];
      }
    }
  };

  return (
    <Link className={_renderClassName()} to={router}>
      {
        toggle ? (
          <div className={styles['icon']}>
            {icon}
          </div>
        ) : (
          <>
            <div className={styles['icon']}>
              {icon}
            </div>
            <p>{title}</p>
          </>
        )
      }
    </Link>
  );
};

export default MenuSidebar;

MenuSidebar.propTypes = {
  checkActive: PropTypes.bool,
  history: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
  icon: PropTypes.object,
  router: PropTypes.string,
  title: PropTypes.string,
  toggle: PropTypes.bool,
};

MenuSidebar.defaultProps = {
  checkActive: false,
  history: null,
  icon: '',
  router: '/',
  title: 'Dashboard',
  toggle: false,
};
