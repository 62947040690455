import { CLEAR, LOADING, LOGIN } from './constants';

const initialState = {
  errorMessage: '',
  loading: false,
  profile: {
    email: '',
    phone: '',
    status: '',
    username: ''
  },
  token: {
    refresh_token: '',
    refresh_token_expires_in: '',
    token: '',
    token_expires_in: ''
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case LOGIN:
      return {
        ...state,
        errorMessage: action.errorMessage,
        profile: action.profile,
        token: action.token
      };
    case CLEAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
