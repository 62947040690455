import React from 'react';
import { ICONS } from '../../../configs';
import { Button } from '../../elements';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

const ModalDelete = (props) => {
  return (
    <div className={styles['modal-delete-stock']}>
      <ICONS.TRASHLARGERED />
      <h4>Do you want to delete this?</h4>
      <p>All processed can’t be undone, and the system will permanently delete the item</p>
      <div className="d-flex">
        <Button children="Yes, Delete It" className={styles['btn-ok']} onClick={props.onDelete} />
        <Button children="No, Cancel" className={styles['btn-cancel']} onClick={props.onCancel} />
      </div>
    </div>
  );
};

export default ModalDelete;

ModalDelete.defaultProps = {
  onCancel: null,
  onDelete: null,
};

ModalDelete.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};
