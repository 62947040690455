import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Button = (props) => {
  const { className, isDisabled, isLoading, onClick, type } = props;
  return (
    <button className={[styles['button'], className].join(' ')} disabled={isDisabled} onClick={onClick} type={type}>
      {isLoading ? <span className="loading">Loading ...</span> : props.children}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  children: null,
  className: '',
  isDisabled: false,
  isLoading: false,
  onClick: null,
  type: 'button',
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
};
