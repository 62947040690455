import React from 'react';
import { Label } from '../../elements';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const _renderBgColor = (status) => {
  if (status === 'Fulfilling') {
    return '#E6934A';
  } else if (status === 'Shipping' || status === 'main') {
    return '#0C87F8';
  } else {
    return '#45B43B';
  }
};

const ModalHeader = ({ date, numberID, title, type, desc, status, name }) => {
  return (
    <div className={styles['modal-header']}>
      <div className="d-flex">
        <h6>{title}</h6>
        {status && (
          <Label
            backgroundColor={_renderBgColor(status)}
            children={status}
            className="ms-2"
            color="#FFFFFF"
            innerClassName={styles['label-header']}
          />
        )}
      </div>
      {
        desc ? <p>{desc}</p> : (
          <div className="d-flex align-items-center">
            <p>{type} <span>{date}</span></p>
            {name && <div className="ms-2 d-flex"><p>By</p> <span className="ms-1">{name}</span></div>}
            <Label backgroundColor="#F9F9F9" children={numberID} className={styles['invoice']} />
          </div>
        )
      }
    </div>
  );
};

export default ModalHeader;

ModalHeader.defaultProps = {
  date: '',
  desc: '',
  name: '',
  numberID: '',
  status: '',
  title: '',
  type: '',
};

ModalHeader.propTypes = {
  date: PropTypes.string,
  desc: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};
