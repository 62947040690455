import React, { useEffect, useState } from 'react';
import { ICONS } from '../../../configs';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
// import { DriverOptions } from '../../pages/Reporting';
import { CheckPicker } from 'rsuite';
import { Label } from '..';
import Modal from '../Modal';
import { ModalAddDriver } from '../../pages/Driver';
import { ModalHeader } from '../../fragments';

const _renderBgColor = (status) => {
  if (status === 'Fulfilling' || status === 'pickup') {
    return '#E6934A';
  } else if (status === 'Shipping' || status === 'active' || status === 'pending' || status === 'good' || status === 'distribution_center' || status === 'fulfilling') {
    return '#0C87F8';
  } else if (status === 'nonactive' || status === 'not_good' || status === 'inactive') {
    return '#9CA3AF';
  } else if (status === 'returned' || status === 'cancel') {
    return '#DE5134';
  } else {
    return '#45B43B';
  }
};

const _renderStatus = (status) => {
  if (status === 'success' || status === 'DONE') {
    return 'Completed';
  } else if (status === 'nonactive') {
    return 'Inactive';
  } else if (status === 'not_good') {
    return 'Not Good';
  } else if (status === 'on_going') {
    return 'On Going';
  } else if (status === 'link_created') {
    return 'Link Created';
  } else if (status === 'partial_refund') {
    return 'Partial Refund';
  } else if (status === 'distribution_center') {
    return 'Distribution Center';
  } else if (status === 'hub') {
    return 'Hub';
  } else if (status === 'PROCESS-TRANSACTION') {
    return 'Transaction Process';
  } else if (status === 'PENDING') {
    return 'Pending';
  } else if (status === 'PROCESS-SHIPMENT') {
    return 'Shipment Process';
  } else if (status === 'CANCEL') {
    return 'Cancel';
  } else if (status === 'delivered') {
    return 'Done';
  } else if (status === 'ready' || status === 'fulfilling') {
    return 'Fulfilling';
  } else {
    return status;
  }
};

const Dropdowns = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const _onChange = (opt) => {
    setValue(opt);
    setOpen(!open);
    props.onChange(opt);
  };

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    props.type === 'CheckPicker' ? (
      <CheckPicker
        className={styles['custom-checkpicker']}
        data={props.dropdowns}
        onChange={(value) => _onChange(value)}
        placeholder="Status"
        searchable={false}
        size="md"
      />
    ) : (
      <div className="position-relative">
        {props.type !== 'active-color' ? (
          <div className={['cursor-pointer', styles['dropdown-custom']].join(' ')} onClick={() => setOpen(!open)}>
            <p className="text-capitalize">{_renderStatus(value)}</p>
            {!open ? <ICONS.ARROWDOWN /> : <ICONS.ARROWUP className={styles['icon-arrow-up']} />}
          </div>
        ) : (
          <Label
            backgroundColor={_renderBgColor(props.value)}
            children={_renderStatus(props.value)}
            className={['d-flex cursor-pointer', styles['custom-dropdown-label']].join(' ')}
            color="#FFFFFF"
            onClick={() => setOpen(!open)}
            open={open}
            type="dropdown"
          />
        )}
        {open && !props.isDriver && (
          <ul className={[styles['dropdown-options'], props.type === 'active-color' ? 'top-100' : ''].join(' ')}>
            {props.dropdowns.length > 0 && (
              props.dropdowns.map((val, idx) => (
                <li
                  key={idx}
                  onClick={() => {
                    if (!props.isDriver) {
                      _onChange(val);
                    } else {
                      _onChange(val.driverName);
                    }
                  }}
                >
                  {/* {!props.isDriver ?
                    <a className={styles[value !== val ? 'dropdown-item' : 'dropdown-item-active']}>{_renderStatus(val)}</a>
                    : (
                      <a className={[styles[value !== val ? 'dropdown-item' : 'dropdown-item-active'], styles['driver']].join(' ')}>
                        <DriverOptions driverName={val.driverName} PLAT={val.PLAT} />
                      </a>
                    )} */}
                  {!props.isDriver &&
                    <a className={styles[value !== val ? 'dropdown-item' : 'dropdown-item-active']}>{_renderStatus(val)}</a>}
                </li>
              ))
            )}
          </ul>
        )}
        {open && props.isDriver && (
          <Modal
            childrenBody={<ModalAddDriver isDriver={props.isDriver} />}
            childrenHeader={<ModalHeader desc="New admin will be added to the table" title="Add Driver" />}
            onClose={() => setOpen(false)}
            onOk={() => setOpen(false)}
            size="large"
          />
        )}
      </div>
    )
  );
};

export default Dropdowns;

Dropdowns.defaultProps = {
  isDriver: false,
  onChange: null,
  type: null
};

Dropdowns.propTypes = {
  dropdowns: PropTypes.array.isRequired,
  isDriver: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
};
