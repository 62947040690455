import { GET_USER } from './constants';

const initialState = {
  color: {
    data: [],
    error: ''
  },
  errorMessage: '',
  user: {
    created_at: '',
    email: '',
    full_name: '',
    last_login_at: '',
    phone: '',
    status: '',
    updated_at: '',
  },
};

export default function reducer(state = initialState, action) {
  if (action.type === GET_USER) {
    return {
      ...state,
      errorMessage: action.errorMessage,
      user: action.user,
    };
  } else {
    return state;
  }
}
