import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SummaryChart = () => {
  const series = [
    {
      data: [21, 25, 28, 35, 19, 23, 35],
      name: 'Earnings',
    }
  ];

  const options = {
    chart: {
      height: 350,
      type: 'area',
    },
    noData: {
      text: 'Loading...',
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        }
      }
    },
    xaxis: {
      categories: [
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
        'Sun'
      ],
      labels: {
        style: {
          colors: '#4B5563',
          cssClass: 'apexcharts-xaxis-label',
          fontFamily: 'Platform, sans-serif',
          fontSize: '12px',
          fontWeight: 400,
        }
      }
    },
    yaxis: {
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          opacityFrom: 0.7,
          opacityTo: 0.9,
          shadeIntensity: 1,
          stops: [0, 90, 100]
        },
        type: 'gradient',
      },
      labels: {
        show: false
      },
      min: 6,
      tickAmount: 5,
      title: {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
          show: true,
        },
        text: undefined,
      },
    },
  };

  return (
    <div>
      <ReactApexChart height={350} options={options} series={series} type="area" />
    </div>
  );
};

export default SummaryChart;
