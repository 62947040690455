import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ICONS } from '../../../configs';
import styles from './styles.module.scss';

const SubMenuSidebar = (props) => {
  const { title, icon, checkActive, subMenu } = props;
  const [up, setUp] = useState(false);
  const history = useHistory();

  const _renderClassName = () => {
    if (checkActive) {
      return styles['sub-menu-item-active'];
    } else {
      return styles['sub-menu-item'];
    }
  };

  return (
    <div className={_renderClassName()}>
      <div className={styles['item']} onClick={() => setUp(prev => !prev)}>
        <div className={styles['sub-item']}>
          <div className={styles['icon']}>
            {icon}
          </div>
          <p>
            {title}
          </p>
        </div>
        {!up ? <ICONS.ARROWDOWN /> : <ICONS.ARROWUP />}
      </div>
      {
        up && (
          <ul>
            {
              subMenu.map((val, index) => (
                <li className={val.checkActive ? styles['li-active'] : styles['li']} key={index} onClick={() => history.push(val.router)}>{val.title}</li>
              ))
            }
          </ul>
        )
      }
    </div>
  );
};

export default SubMenuSidebar;

SubMenuSidebar.propTypes = {
  checkActive: PropTypes.bool,
  history: PropTypes.oneOfType([PropTypes.any, PropTypes.func]),
  icon: PropTypes.object,
  subMenu: PropTypes.array,
  title: PropTypes.string
};

SubMenuSidebar.defaultProps = {
  checkActive: false,
  history: null,
  icon: '',
  subMenu: [],
  title: 'Dashboard'
};
