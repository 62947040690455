import { ADD_PRODUCT, DELETE_PRODUCT, EDIT_PRODUCT, FETCH_PRODUCT, PRODUCT_ID } from './constants';

const initialState = {
  errorMessage: '',
  loading: false,
  product: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  productId: {
    categories: [],
    images: [],
    product: {
      bpom: '',
      brand: '',
      cogs_average: 0,
      cogs_total: 0,
      created_at: '',
      created_by: '',
      description: '',
      id: 0,
      mui: '',
      name: '',
      qty: 0,
      selling_price: 0,
      size: 0,
      sku: '',
      status: '',
      uom: '',
      updated_at: '',
      updated_by: '',
    }
  },
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        product: action.payload
      };
    case PRODUCT_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        productId: {
          categories: action.payload.categories,
          images: action.payload.images,
          product: action.payload.product,
        }
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    case ADD_PRODUCT:
    case EDIT_PRODUCT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    default:
      return state;
  }
}
