import { LOADING } from '../Auth/constants';
import { CRUD_SUPPLIER, ERROR_MESSAGE, FETCH_SUPPLIER, LOADING_BEGIN, SUPPLIER_ID } from './constants';

const initialState = {
  errorMessage: '',
  loading: false,
  success: false,
  supplier: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  supplierId: {
    address: '',
    code: '',
    created_at: '',
    created_by: 0,
    credit_limit: 0,
    fax: '',
    id: 0,
    model: '',
    name: '',
    notes: '',
    phone: '',
    pic: '',
    type: '',
    updated_at: '',
    updated_by: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIER:
      return {
        ...state,
        supplier: action.payload
      };
    case SUPPLIER_ID:
      return {
        ...state,
        supplierId: action.payload
      };
    case LOADING:
    case LOADING_BEGIN:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CRUD_SUPPLIER:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success,
      };
    default:
      return state;
  }
}
