import React, { useEffect, useRef, useState } from 'react';
import { ICONS } from '../../../configs';
import { Button, Tabs } from '..';
import InputText from '../../fields/InputText';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import SearchField from '../SearchField';
import { useDispatch, useSelector } from 'react-redux';
import { SEARCH_GLOBAL } from '../../../pages/constants';

const Header = ({ tabs, onChange, exportData, loadingExport, canExport, isSearch, onSearch }) => {
  const [value, setValue] = useState('');
  const wrapperRef = useRef(null);
  const { searchData } = useSelector(state => state.pages);
  const dispatch = useDispatch();

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          dispatch({ payload: [], type: SEARCH_GLOBAL });
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const _renderHeader = () => {
    if (isSearch) {
      return (
        <section className={[styles['header-custom'], canExport && tabs.length < 0 ? 'd-flex justify-content-end' : 'd-flex justify-content-between'].join(' ')}>
          <div className="relative">
            <InputText
              append={<ICONS.SEARCH />}
              appendCustom={styles['append-custom']}
              inputClassName={styles['header-search']}
              name="search"
              onChange={(e) => {
                setValue(e.target.value);
                if (e.target.value.length >= 3) {
                  onSearch(e.target.value);
                }
              }}
              onClick={() => {
                if (value.length >= 3) {
                  onSearch(value);
                }
              }}
              outerClassName="mb-0"
              placeholder="Search"
              value={value}
              wrapperRef={wrapperRef}
            />
            {searchData.length > 0 && <SearchField />}
          </div>
          <div className="d-flex justify-content-end">
            {tabs.length > 0 && <Tabs onChange={onChange} tabs={tabs} />}
            {canExport && (
              <Button
                className={['btn ms-4', styles['btn-export']].join(' ')}
                isLoading={loadingExport}
                onClick={exportData}
                type="button"
              >
                <ICONS.EXPORT className={styles['icon-export']} />Export
              </Button>
            )}
          </div>
        </section>
      );
    } else {
      return (
        <section className={[styles['header-custom'], canExport && tabs.length <= 0 ? 'd-flex justify-content-end' : 'd-flex justify-content-between'].join(' ')}>
          {tabs.length > 0 && <Tabs onChange={onChange} tabs={tabs} />}
          {canExport && (
            <Button
              className={['btn', styles['btn-export']].join(' ')}
              isLoading={loadingExport}
              onClick={exportData}
              type="button"
            >
              <ICONS.EXPORT className={styles['icon-export']} />Export
            </Button>
          )}
        </section>
      );
    }
  };

  return _renderHeader();
};

export default Header;

Header.defaultProps = {
  canExport: true,
  exportData: null,
  isSearch: false,
  loadingExport: false,
  onChange: null,
  onSearch: null,
  tabs: []
};

Header.propTypes = {
  canExport: PropTypes.bool,
  exportData: PropTypes.func,
  isSearch: PropTypes.bool,
  loadingExport: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  tabs: PropTypes.array
};
