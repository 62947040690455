import { ReactComponent as ACTIVITY } from '../assets/icons/ic-activity.svg';
import { ReactComponent as ARROWDOWN } from '../assets/icons/ic-arrow-down.svg';
import { ReactComponent as ARROWLEFT } from '../assets/icons/ic-arrow-left.svg';
import { ReactComponent as ARROWRIGHT } from '../assets/icons/ic-arrow-right.svg';
import { ReactComponent as ARROWUP } from '../assets/icons/ic-arrow-up.svg';
import { ReactComponent as BASKET } from '../assets/icons/ic-basket.svg';
import { ReactComponent as CIRCLESMALL } from '../assets/icons/ic-circle-small.svg';
import { ReactComponent as CLOSE } from '../assets/icons/ic-close.svg';
import { ReactComponent as DASHBOARD } from '../assets/icons/ic-dashboard.svg';
import { ReactComponent as DELETE } from '../assets/icons/ic-delete.svg';
import { ReactComponent as EDIT } from '../assets/icons/ic-edit.svg';
import { ReactComponent as ERROR } from '../assets/icons/ic-error.svg';
import { ReactComponent as ERRORMOBILE } from '../assets/icons/ic-error-mobile.svg';
import { ReactComponent as EXPORT } from '../assets/icons/ic-export.svg';
import { ReactComponent as EYESACTIVE } from '../assets/icons/ic-eyes-active.svg';
import { ReactComponent as EYESSLASHACTIVE } from '../assets/icons/ic-eyes-slash-active.svg';
import { ReactComponent as EYESSLASH } from '../assets/icons/ic-eyes-slash.svg';
import { ReactComponent as FLOWER } from '../assets/icons/ic-flower.svg';
import { ReactComponent as IMAGE } from '../assets/icons/ic-image.svg';
import { ReactComponent as INBOUND } from '../assets/icons/ic-inbound.svg';
import { ReactComponent as INVENTORY } from '../assets/icons/ic-inventory.svg';
import { ReactComponent as IMAGEDISABLED } from '../assets/icons/ic-image-disabled.svg';
import { ReactComponent as IMAGEHOVER } from '../assets/icons/ic-image-hover.svg';
import { ReactComponent as INVOICE } from '../assets/icons/ic-invoice.svg';
import { ReactComponent as LOGOSWADELI } from '../assets/icons/ic-logo-swadeli.svg';
import { ReactComponent as LOGOSWADELIDASHBOARD } from '../assets/icons/ic-logo-swadeli-dashboard.svg';
import { ReactComponent as LOGOUT } from '../assets/icons/ic-logout.svg';
import { ReactComponent as MASTERDATA } from '../assets/icons/ic-master-data.svg';
import { ReactComponent as NOTIFICATIONS } from '../assets/icons/ic-notifications.svg';
import { ReactComponent as OUTBOUND } from '../assets/icons/ic-outbound.svg';
import { ReactComponent as PENDINGMOBILE } from '../assets/icons/ic-pending-mobile.svg';
import { ReactComponent as PLUS } from '../assets/icons/ic-plus.svg';
import { ReactComponent as RECEIPT } from '../assets/icons/ic-receipt.svg';
import { ReactComponent as RELOAD } from '../assets/icons/ic-reload.svg';
import { ReactComponent as REPORTING } from '../assets/icons/ic-reporting.svg';
import { ReactComponent as REVENUE } from '../assets/icons/ic-revenue.svg';
import { ReactComponent as SALES } from '../assets/icons/ic-sales.svg';
import { ReactComponent as SEARCH } from '../assets/icons/ic-search.svg';
import { ReactComponent as SHOPPINGBAG } from '../assets/icons/ic-shopping-bag.svg';
import { ReactComponent as STOCK } from '../assets/icons/ic-stock.svg';
import { ReactComponent as SUCCESS } from '../assets/icons/ic-success.svg';
import { ReactComponent as SUCCESSMOBILE } from '../assets/icons/ic-success-mobile.svg';
import { ReactComponent as TRANSFER } from '../assets/icons/ic-transfer.svg';
import { ReactComponent as TRASH } from '../assets/icons/ic-trash.svg';
import { ReactComponent as TRASHLARGERED } from '../assets/icons/ic-trash-large-red.svg';
import { ReactComponent as USER } from '../assets/icons/ic-user.svg';
import { ReactComponent as USERMANAGEMENT } from '../assets/icons/ic-user-management.svg';

const icons = {
  ACTIVITY,
  ARROWDOWN,
  ARROWLEFT,
  ARROWRIGHT,
  ARROWUP,
  BASKET,
  CIRCLESMALL,
  CLOSE,
  DASHBOARD,
  DELETE,
  EDIT,
  ERROR,
  ERRORMOBILE,
  EXPORT,
  EYESACTIVE,
  EYESSLASH,
  EYESSLASHACTIVE,
  FLOWER,
  IMAGE,
  IMAGEDISABLED,
  IMAGEHOVER,
  INBOUND,
  INVENTORY,
  INVOICE,
  LOGOSWADELI,
  LOGOSWADELIDASHBOARD,
  LOGOUT,
  MASTERDATA,
  NOTIFICATIONS,
  OUTBOUND,
  PENDINGMOBILE,
  PLUS,
  RECEIPT,
  RELOAD,
  REPORTING,
  REVENUE,
  SALES,
  SEARCH,
  SHOPPINGBAG,
  STOCK,
  SUCCESS,
  SUCCESSMOBILE,
  TRANSFER,
  TRASH,
  TRASHLARGERED,
  USER,
  USERMANAGEMENT
};

export default icons;
