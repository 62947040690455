import React from 'react';
import { ICONS } from '../../../configs';
import { Card, Label, Table } from '../../elements';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const SummaryTable = ({ data, title, subTitle, type, thead }) => {
  const _renderBgColor = (status) => {
    if (status === 'Waiting for Payment') {
      return '#0C87F8';
    } else if (status === 'Paid') {
      return '#45B43B';
    } else {
      return '#E94D27';
    }
  };

  const _tableBody = (item) => {
    return (
      type === 'activites' ? (
        <tr key={item._id}>
          {/* <td>{item._id}</td> */}
          <td>{item.invoice}</td>
          <td>{item.date}</td>
          <td>{item.user}</td>
          <td>{item.paymentMethod}</td>
          <td><Label backgroundColor={_renderBgColor(item.status)} children={item.status} color="#FFFFFF" /></td>
          <td className="text-medium">{item.total}</td>
        </tr>
      ) : (
        <tr key={item._id}>
          {/* <td>{item._id}</td> */}
          <td>{item.sku}</td>
          <td>{item.brand}</td>
          <td>{item.productName}</td>
          <td>{item.size}</td>
          <td>{item.uom}</td>
          <td>{item.stock}</td>
          <td>{item.hub}</td>
        </tr>
      )
    );
  };

  _tableBody.propTypes = {
    item: PropTypes.object.isRequired
  };

  return (
    <Card>
      <h6>{title} <ICONS.RELOAD className={styles['reload-icon']} /></h6>
      <p>{subTitle}</p>
      <Table
        children={(item) => _tableBody(item)}
        data={type === 'activites' ? data.activites : data.stock}
        thead={thead}
      />
    </Card>
  );
};

export default SummaryTable;

SummaryTable.defaultProps = {
  subTitle: '',
  title: ''
};

SummaryTable.propTypes = {
  data: PropTypes.object.isRequired,
  subTitle: PropTypes.string,
  thead: PropTypes.array.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired
};
