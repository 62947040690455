import React, { useEffect, useRef, useState } from 'react';
import { ICONS } from '../../../configs';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import { formatBytes, previewFile } from '../../../utils/others';
import { useDispatch } from 'react-redux';
import { setAlert } from '../Alert/actions';
import { Loader, Progress } from 'rsuite';
import Compress from 'browser-image-compression';

const UploadImage = ({ onChange, getImage, percent, deleteImage }) => {
  let uploadRef = useRef();
  const [image, setImage] = useState([
    {}
  ]);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const _renderIconImg = () => {
    if (!image) {
      return <ICONS.IMAGEDISABLED />;
    } else {
      return <ICONS.IMAGE />;
    }
  };

  const _onUpload = (event) => {
    const file = event.target.files;
    if (file[file.length - 1]) {
      if (file[file.length - 1].type === 'image/jpeg' || file[file.length - 1].type === 'image/jpg' || file[file.length - 1].type === 'image/png') {
        // Compression config
        const options = {
          // As the key specify the maximum size
          // Leave blank for infinity
          maxSizeMB: 2,
          // Use webworker for faster compression with
          // the help of threads
          useWebWorker: true
        };
        setLoading(true);
        Compress(file[file.length - 1], options)
          .then(compressedBlob => {
            // If you want to work with the File
            // Let's convert it here, by adding a couple of attributes
            compressedBlob.lastModifiedDate = new Date();

            // Conver the blob to file
            setLoading(false);
            const convertedBlobFile = new File([compressedBlob], file[file.length - 1].name, { lastModified: Date.now(), type: file[file.length - 1].type });
            if (convertedBlobFile.size < 5242880) {
              previewFile(file[file.length - 1], value => {
                setImage(value);
                setFiles([...files, {
                  image: value,
                  name: convertedBlobFile.name,
                  size: convertedBlobFile.size
                }]);
              });
              onChange(convertedBlobFile);
            } else {
              dispatch(setAlert({ message: 'Gagal mengunggah, max size 5mb', type: 'danger' }));
            }
          })
          .catch(e => {
            setLoading(false);
            console.log(e);
          });
      } else {
        dispatch(setAlert({ message: 'Gagal mengunggah, format image hanya JPEG, JPG, PNG', type: 'danger' }));
      }
    }
    event.target.files[0].value = '';
  };

  useEffect(() => {
    if (getImage.length > 0) {
      setFiles(getImage);
    }
  }, [getImage]);

  return (
    <div className="d-flex flex-column">
      {files.length < 5 && (
        <div
          className={[styles['upload-container-disabled'], 'position-relative'].join(' ')}
          onClick={(e) => {
            e = e || window.event;
            if (e.target.id !== 'file') {
              uploadRef.click();
            }
          }}
        >
          <input
            accept="image/*"
            className={['position-absolute', styles['form-control-file']].join(' ')}
            id="file"
            onChange={(e) => _onUpload(e)}
            onClick={(event) => {
              event.target.value = null;
            }}
            ref={input => uploadRef = input}
            type="file"
          />
          <div className="d-flex text-center flex-column justify-content-center align-items-center position-absolute end-0 start-0 bottom-0" style={{ height: '114px' }}>
            {loading ? (
              <Loader center size="md" />
            ) : (
              <>
                {_renderIconImg()}
                <h6>Upload Image</h6>
                <p>.IMG up to 5MB.</p>
              </>
            )}
          </div>
        </div>
      )}
      {files.length > 0 && (
        files.map((file, idx) => (
          <div className="mt-4 d-flex align-items-center" key={idx}>
            {file.image ? (
              <>
                <img alt="image product" className={styles['img-result']} src={file.image} />
                <div className="ms-4 w-full">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6 className={styles['name-image']}>{file.name}</h6>
                      <p className={styles['size-image']}>{formatBytes(file.size)}</p>
                    </div>
                    <ICONS.CLOSE className="cursor-pointer" onClick={() => {
                      deleteImage(idx);
                      const newImages = files.filter((_, index) => index !== idx);
                      setFiles([...newImages]);
                    }} />
                  </div>
                  <Progress.Line className="m-0 p-0" percent={percent[idx]} status="active" />
                </div>
              </>
            ) : (
              <>
                <img alt="image product" className={styles['img-result']} src={file} />
                <div className="ms-4 w-full">
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className={styles['name-image']}>{file}</h6>
                    <ICONS.CLOSE className="cursor-pointer" onClick={() => {
                      deleteImage(idx);
                      const newImages = files.filter((_, index) => index !== idx);
                      setFiles([...newImages]);
                    }} />
                  </div>
                  <Progress.Line className="m-0 p-0" percent={percent[idx]} status="active" />
                </div>
              </>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default UploadImage;

UploadImage.defaultProps = {
  deleteImage: null,
  getImage: [],
  onChange: null,
  percent: [],
};

UploadImage.propTypes = {
  deleteImage: PropTypes.func,
  getImage: PropTypes.array,
  onChange: PropTypes.func,
  percent: PropTypes.array,
};
