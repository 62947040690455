import React from 'react';
import { ICONS } from '../../../configs';
import { Button } from '../../elements';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import InputText from '../../fields/InputText';

const ModalConfirm = (props) => {
  return (
    <div className={styles['modal-delete-stock']}>
      <ICONS.RECEIPT />
      <h4>{props.title}</h4>
      {props.type !== 'stock' && <p>{props.desc}</p>}
      {props.type === 'stock' && (
        <InputText
          inputClassName={styles['input-add']}
          label="Stock"
          name="stock"
          onChange={(e) => props.onChange(e.target.value)}
          placeholder="Stock"
          value={props.stock}
        />
      )}
      <div className="d-flex">
        <Button children={props.cancelText} className={styles['btn-cancel']} onClick={props.onCancel} />
        <Button children={props.okText} className={styles['btn-ok']} onClick={props.onOk} />
      </div>
    </div>
  );
};

export default ModalConfirm;

ModalConfirm.defaultProps = {
  cancelText: 'Cancel',
  desc: '',
  okText: 'Confirm Number',
  onCancel: null,
  onChange: null,
  onOk: null,
  stock: '',
  title: 'Please confirm the number of quantity received',
  type: 'stock',
};

ModalConfirm.propTypes = {
  cancelText: PropTypes.string,
  desc: PropTypes.string,
  okText: PropTypes.string,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onOk: PropTypes.func,
  stock: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};
