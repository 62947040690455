import { LOADING } from '../Auth/constants';
import { ERROR_MESSAGE, FETCH_CATEGORY, LOADING_BEGIN, CATEGORY_ID, CRUD_CATEGORY } from './constants';

const initialState = {
  category: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  categoryId: {
    category: {
      created_at: '',
      created_by: '',
      cut_off_date: 0,
      edited_at: '',
      id: 0,
      name: '',
      parent_id: 0,
      updated_at: '',
      updated_by: 0,
    },
    images: [],
    sub_category: []
  },
  errorMessage: '',
  loading: false,
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY:
      return {
        ...state,
        category: action.payload,
        success: false
      };
    case CATEGORY_ID:
      return {
        ...state,
        categoryId: {
          category: action.payload.category,
          images: action.payload.images,
          sub_category: action.payload.sub_category
        }
      };
    case LOADING:
    case LOADING_BEGIN:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CRUD_CATEGORY:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    default:
      return state;
  }
}
