/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ICONS } from '../../../configs';

const ModalScroll = (props) => {
  const { childrenHeader, childrenBody, onClose } = props;
  return (
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" className="modal fade show" id="exampleModal" tabIndex="-1">
      <div className={`modal-dialog modal-dialog-centered modal-dialog-scrollable ${props.size === 'large' ? 'modal-lg' : ''}`}>
        <div className="modal-content">
          <div className="modal-header">
            {childrenHeader}
            <ICONS.CLOSE className="cursor-pointer" onClick={onClose} />
          </div>
          <div className="modal-body p-0">
            {childrenBody}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalScroll;

ModalScroll.defaultProps = {
  buttonTitle: '',
  childrenBody: null,
  childrenHeader: null,
  haveButton: false,
  onOk: null,
  size: 'large'
};

ModalScroll.propTypes = {
  buttonTitle: PropTypes.string,
  childrenBody: PropTypes.node,
  childrenHeader: PropTypes.node,
  haveButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  size: PropTypes.string
};
