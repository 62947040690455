import { FETCH_OUTBOUND, OUTBOUND_ID } from './constants';

const initialState = {
  errorMessage: '',
  outbound: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  outboundId: {
    cogs: 0,
    created_at: '',
    created_by: '',
    cut_off_date: '',
    delivery_note: '',
    expired_at: '',
    hub_id: 0,
    hub_name: '',
    id: 0,
    last_update_user_id: 0,
    position: '',
    price: 0,
    product_brand: '',
    product_id: 0,
    product_name: '',
    product_sku: '',
    status: '',
    stock: 0,
    updated_at: '',
    updated_by: '',
    user_id: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OUTBOUND:
      return {
        ...state,
        errorMessage: action.errorMessage,
        outbound: action.payload
      };
    case OUTBOUND_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        outboundId: action.payload
      };
    default:
      return state;
  }
}
