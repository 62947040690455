import { useDispatch } from 'react-redux';
import { setAlert } from '../../components/elements/Alert/actions';
import fetch from '../../utils/fetch';
import { ADD_INBOUND, CATEGORY_DROPDOWN, FETCH_INBOUND, GET_PRODUCT_NAME, HUB_DROPDOWN, INBOUND_ID, PRODUCT_DROPDOWN, SUB_CATEGORY_DROPDOWN, SUPPLIER_DROPDOWN } from './constants';

export function useInbound() {
  const dispatch = useDispatch();
  return {
    addInbound: async (payload) => {
      dispatch({ errorMessage: '', loading: true, success: false, type: ADD_INBOUND });
      try {
        const data = await fetch.post(`${process.env.REACT_APP_API}/inbound`, payload);
        if (data.data.msg[0] === 'created') {
          dispatch(setAlert({ message: 'Successfully added inbound', type: 'success' }));
          const data = await fetch.get(`${process.env.REACT_APP_API}/inbound/list?order=desc&by=created_at&search=&page=1&limit=10`);
          dispatch({ errorMessage: '', payload: data.data.data, type: FETCH_INBOUND });
          dispatch({ errorMessage: '', payload: '', type: GET_PRODUCT_NAME });
        }
        dispatch({ errorMessage: '', loading: false, success: true, type: ADD_INBOUND });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', loading: false, success: false, type: ADD_INBOUND });
        dispatch({ errorMessage: '', payload: '', type: GET_PRODUCT_NAME });
      }
    },
    getCategoryDropdown: async () => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/category/dropdown`);
        dispatch({ errorMessage: '', payload: data.data.data, type: CATEGORY_DROPDOWN });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', payload: [], type: CATEGORY_DROPDOWN });
      }
    },
    getHubDropdown: async () => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/hub/dropdown`);
        dispatch({ errorMessage: '', payload: data.data.data, type: HUB_DROPDOWN });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', payload: [], type: HUB_DROPDOWN });
      }
    },
    getInboundById: async (payload) => {
      const { id } = payload;
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/inbound/${id}`);
        if (data.data.data) {
          dispatch({ errorMessage: '', payload: data.data.data, type: INBOUND_ID });
        }
      } catch (error) {
        dispatch({
          errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch',
          payload: {
            cogs: 0,
            created_at: '',
            created_by: '',
            cut_off_date: '',
            delivery_note: '',
            expired_at: '',
            hub_id: 0,
            hub_name: '',
            id: 0,
            last_update_user_id: 0,
            position: '',
            price: 0,
            product_brand: '',
            product_id: 0,
            product_name: '',
            product_sku: 0,
            status: '',
            stock: 0,
            supplier_id: 0,
            supplier_name: '',
            updated_at: '',
            updated_by: '',
          },
          type: INBOUND_ID
        });
        dispatch(setAlert({ message: error.response ? error.response.data.msg[0] : 'Failed fetch', type: 'danger' }));
      }
    },
    getListInbound: async (payload) => {
      const { order, by, page, limit, search, start_date, end_date, status, hub } = payload;
      const params = `page=${page}&limit=${limit}&start_date=${start_date}&end_date=${end_date}${hub ? `&hub=${hub}` : ''}${status ? `&status=${status}` : ''}`;
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/inbound/list?order=${order}&by=${by}&search=${search}&${params}`);
        dispatch({ errorMessage: '', payload: data.data.data, type: FETCH_INBOUND });
      } catch (error) {
        dispatch({
          errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch',
          payload: {
            HasNext: false,
            Records: [],
            TotalData: 1,
            TotalPage: 1,
          },
          type: FETCH_INBOUND
        });
      }
    },
    getProductDropdown: async () => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/product/dropdown`);
        dispatch({ errorMessage: '', payload: data.data.data, type: PRODUCT_DROPDOWN });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', payload: [], type: PRODUCT_DROPDOWN });
      }
    },
    getProductName: async (sku) => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/product?sku=${sku}`);
        dispatch({ errorMessage: '', payload: data.data.data, type: GET_PRODUCT_NAME });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', payload: '', type: GET_PRODUCT_NAME });
      }
    },
    getSubCategoryDropdown: async () => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/category/sub/dropdown`);
        dispatch({ errorMessage: '', payload: data.data.data, type: SUB_CATEGORY_DROPDOWN });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', payload: [], type: SUB_CATEGORY_DROPDOWN });
      }
    },
    getSupplierDropdown: async () => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/supplier/dropdown`);
        dispatch({ errorMessage: '', payload: data.data.data, type: SUPPLIER_DROPDOWN });
      } catch (error) {
        dispatch({ errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch', payload: [], type: SUPPLIER_DROPDOWN });
      }
    },
  };
}
