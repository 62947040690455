import React, { useState } from 'react';
import { Button, UploadImage } from '../../../elements';
import InputText from '../../../fields/InputText';
import styles from './styles.module.scss';

const ModalEditDriver = () => {
  const [form, setForm] = useState({
    email: '',
    name: '',
    userID: '',
  });
  const disabled =
    form.userID === '' || form.name === '' || form.email === '';

  const _onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  return (
    <div className={[styles['modal-add-admin'], 'container-fluid'].join(' ')}>
      <div className="row">
        <InputText
          inputClassName={styles['input-add']}
          label="User ID"
          name="userID"
          onChange={_onChange}
          placeholder="User ID"
          value={form.userID}
        />
        <div className="col-6">
          <InputText
            inputClassName={styles['input-add']}
            label="Name"
            name="name"
            onChange={_onChange}
            placeholder="Name"
            value={form.name}
          />
        </div>
        <div className="col-6">
          <InputText
            inputClassName={styles['input-add']}
            label="Email"
            name="email"
            onChange={_onChange}
            placeholder="Email"
            value={form.email}
          />
        </div>
        <div className={styles['upload']}>
          <label className={styles['label']}>Driver Image</label>
          <UploadImage />
        </div>
        <Button children="Submit" className={styles[!disabled ? 'btn-submit' : 'btn-disabled']} isDisabled={disabled} />
      </div>
    </div>
  );
};

export default ModalEditDriver;
