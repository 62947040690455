import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { ICONS } from '../../../configs';

const Label = ({ children, backgroundColor, color, className, innerClassName, type, open, onClick }) => {
  return (
    <div className={[styles['label'], className].join(' ')} onClick={onClick} style={{ backgroundColor }}>
      <p className={innerClassName} style={{ color }}>{children}</p>
      {
        type === 'dropdown' && (
          !open ? <ICONS.ARROWDOWN className={styles['icon-arrow-up']} /> : <ICONS.ARROWUP className={styles['icon-arrow-up']} />
        )
      }
    </div>
  );
};

export default Label;

Label.propTypes = {
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerClassName: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string
};

Label.defaultProps = {
  backgroundColor: '#FFFFFF',
  children: 'Swadeli Web',
  className: '',
  color: '#4B5563',
  innerClassName: '',
  onClick: null,
  open: false,
  type: null,
};
