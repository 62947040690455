import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Card = ({ content, title, icon, className, children }) => {
  return (
    <div className={[styles['card'], className].join(' ')}>
      <div className={[styles['card-body-custom'], 'card-body'].join(' ')}>
        {title && <h5 className={styles['card-title']}>{title}</h5>}
        {content && <p className={styles['card-text']}>{icon && icon}{content}</p>}
        {children}
      </div>
    </div>
  );
};

export default Card;

Card.defaultProps = {
  children: null,
  className: '',
  content: null,
  icon: null,
  title: ''
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  title: PropTypes.string
};
