import { useDispatch } from 'react-redux';
import fetch from '../../utils/fetch';
import { GET_USER } from './constants';

export function useDashboard() {
  const dispatch = useDispatch();
  return {
    GetUser: async () => {
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/user/profile`);
        dispatch({ errorMessage: '', type: GET_USER, user: data.data.data });
      } catch (error) {
        if (error.response && error.response.data.msg[0] === 'token is invalid') {
          localStorage.clear();
          location.reload();
        }
      }
    },
  };
}
