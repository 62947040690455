import { CRUD_SPECIAL_HOURS, ERROR_MESSAGE, FETCH_SPECIAL_HOUR, LOADING, LOADING_BEGIN } from './constants';

const initialState = {
  errorMessage: '',
  loading: false,
  specialHour: [],
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SPECIAL_HOUR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        specialHour: action.payload
      };
    case LOADING:
    case LOADING_BEGIN:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CRUD_SPECIAL_HOURS:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    default:
      return state;
  }
}
