import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ErrorBoundary, LazyFallback } from './components/elements';
import ProtectedRoute from './middlewares/ProtectedRoute';
import pages from './pages';
import store from './store/configureStore';
import DefaultLayouts from './components/layouts/DefaultLayouts';
import { useDashboard } from './pages/Dashboard/actions';
import Error404 from './pages/Error404';
import { useInbound } from './pages/Inbound/actions';

export const MainPages = () => {
  const { GetUser } = useDashboard();
  const { getHubDropdown } = useInbound();

  useEffect(() => {
    const load = async () => {
      await GetUser();
      await getHubDropdown();
    };
    load();
  }, []);

  return (
    <DefaultLayouts>
      <Switch>
        <Route component={pages.Dashboard} exact path="/" />
        <Route component={pages.Dashboard} exact path="/dashboard" />
        <Route component={pages.Order} exact path="/order" />
        <Route component={pages.Transaction} exact path="/transaction" />
        <Route component={pages.Shipment} exact path="/shipment" />
        <Route component={pages.Product} exact path="/product" />
        <Route component={pages.Inventory} exact path="/inventory" />
        <Route component={pages.Inbound} exact path="/inbound" />
        <Route component={pages.Outbound} exact path="/outbound" />
        <Route component={pages.Admin} exact path="/admin" />
        <Route component={pages.Customer} exact path="/customer" />
        <Route component={pages.Driver} exact path="/driver" />
        <Route component={pages.Supplier} exact path="/supplier" />
        <Route component={pages.Transfer} exact path="/transfer" />
        <Route component={pages.Hub} exact path="/hub" />
        <Route component={pages.Category} exact path="/category" />
        <Route component={pages.Banner} exact path="/banner" />
        <Route component={pages.InvetoryAdjustment} exact path="/adjustment" />
        <Route component={pages.SpecialHour} exact path="/special-hour" />
        <Route component={pages.StandardHour} exact path="/standard-hour" />
        <Route component={pages.Error404} />
      </Switch>
    </DefaultLayouts>
  );
};

function App() {
  const [view, setView] = useState(window.screen.width > 1279);

  useEffect(() => {
    window.addEventListener('resize', () => {
      const { width } = window.screen;
      if (width < 1279) {
        setView(false);
      } else {
        setView(true);
      }
    });
  }, []);

  const _renderMobile = () => {
    if (location.pathname === '/finish' || location.pathname === '/error' || location.pathname === '/pending') {
      return (
        <BrowserRouter>
          <ErrorBoundary>
            <React.Suspense fallback={<LazyFallback />}>
              <Switch>
                <Route component={pages.Finish} exact key="Finish" path="/finish" />
                <Route component={pages.Error} exact key="Error" path="/error" />
                <Route component={pages.Pending} exact key="Pending" path="/pending" />
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      );
    } else {
      return <Error404 />;
    }
  };

  if (view) {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary>
            <React.Suspense fallback={<LazyFallback />}>
              <Switch>
                <Route component={pages.Login} exact key="Login" path="/login" />
                <ProtectedRoute component={MainPages} name="Home" path="/" />
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    );
  } else {
    return _renderMobile();
  }
}

export default App;
