import { CRUD_STANDARD_HOURS, ERROR_MESSAGE, FETCH_STANDARD_HOUR, LOADING, LOADING_BEGIN } from './constants';

const initialState = {
  errorMessage: '',
  loading: false,
  standardHour: [],
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STANDARD_HOUR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        standardHour: action.payload
      };
    case LOADING:
    case LOADING_BEGIN:
      return {
        ...state,
        loading: action.payload,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      };
    case CRUD_STANDARD_HOURS:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    default:
      return state;
  }
}
