import React from 'react';
import Button from '../Button';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends React.Component {
  state = { error: null };

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    return (
      <section className={styles['error-boundary']}>
        <h1>Error loading page</h1>
        <Button children="Reload" className={styles['btn-error']} onClick={() => location.reload()} />
      </section>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
