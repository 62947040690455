import { SET_ALERT } from './constants';

const initialState = {
  message: '',
  type: 'hide',
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  if (type === SET_ALERT) {
    return {
      ...state,
      ...payload,
    };
  } else {
    return state;
  }
}
