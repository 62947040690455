import { useDispatch } from 'react-redux';
import { setAlert } from '../../components/elements/Alert/actions';
import fetch from '../../utils/fetch';
import { FETCH_SHIPMENT, SHIPMENT_ID, UPDATE_STATUS } from './constants';

const _dispatchGetList = async (dispatch, payload) => {
  const { order, by, page, limit, search, hub, status } = payload;
  const params =
      `order=${order}&by=${by}&search=${search}&page=${page}&limit=${limit}${hub ? `&hub=${hub}` : ''}${status ? `&status=${status}` : ''}`;
  try {
    const data = await fetch.get(
      `${process.env.REACT_APP_API}/order/shipment/list?${params}`);
    dispatch({ errorMessage: '', payload: data.data.data, type: FETCH_SHIPMENT });
  } catch (error) {
    dispatch({
      errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch',
      payload: {
        HasNext: false,
        Records: [],
        TotalData: 0,
        TotalPage: 0,
      },
      type: FETCH_SHIPMENT
    });
  }
};

export function useShipment() {
  const dispatch = useDispatch();
  return {
    getListShipment: async (payload) => {
      _dispatchGetList(dispatch, payload);
    },
    getShipmentById: async (payload) => {
      const { id } = payload;
      try {
        const data = await fetch.get(`${process.env.REACT_APP_API}/order/receipt/shipment/${id}`);
        dispatch({ errorMessage: '', payload: data.data.data, successFetchId: true, type: SHIPMENT_ID });
      } catch (error) {
        dispatch({
          errorMessage: error.response ? error.response.data.msg[0] : 'Failed fetch',
          payload: {
            receipt_shipment: {
              created_at: '',
              driver_name: '',
              full_name: '',
              invoice_number: '',
              phone: '',
              street: '',
            },
            receipt_shipment_tracking: []
          },
          successFetchId: false,
          type: SHIPMENT_ID
        });
        dispatch(setAlert({ message: error.response ? error.response.data.msg[0] : 'Failed fetch', type: 'danger' }));
      }
    },
    updateStatus: async (form, shipmentId, payload) => {
      dispatch({
        errorUpdateStatus: '',
        loading: true,
        payload: false,
        successUpdate: false,
        type: UPDATE_STATUS,
      });
      try {
        const data = await fetch.put(`${process.env.REACT_APP_API}/order/shipment/${shipmentId}`, form);
        if (data.data.msg[0] === 'ok') {
          dispatch({
            errorUpdateStatus: '',
            loading: false,
            payload: true,
            successUpdate: true,
            type: UPDATE_STATUS,
          });
          _dispatchGetList(dispatch, payload);
        }
      } catch (error) {
        dispatch({
          errorUpdateStatus: error.response ? error.response.data.msg[0] : 'Failed fetch',
          loading: false,
          payload: false,
          successUpdate: false,
          type: UPDATE_STATUS,
        });
        dispatch(setAlert({ message: error.response ? error.response.data.msg[0] : 'Failed fetch', type: 'danger' }));
      }
    },
  };
}
