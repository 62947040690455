import { ADD_BANNER, BANNER_ID, FETCH_BANNER } from './constants';

const initialState = {
  banner: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  bannerId: {
    banner: {
      app_redirect: '',
      created_at: '',
      created_by: 0,
      description: '',
      from_date: '',
      id: 0,
      title: '',
      to_date: '',
      updated_at: '',
      updated_by: 0,
      url_redirect: '',
    },
    images: []
  },
  errorMessage: '',
  loading: false,
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BANNER:
      return {
        ...state,
        banner: action.payload,
        errorMessage: action.errorMessage,
      };
    case BANNER_ID:
      return {
        ...state,
        bannerId: action.payload,
        errorMessage: action.errorMessage,
      };
    case ADD_BANNER:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    default:
      return state;
  }
}
