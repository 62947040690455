import { FETCH_ORDER, ORDER_ID } from './constants';

const initialState = {
  errorMessage: '',
  order: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  orderId: {
    receipt_products: [],
    receipt_total: {
      promo: 0,
      shipment_fee: 0,
      subtotal: 0,
      total: 0
    },
    receipt_user: {
      created_at: '',
      full_name: '',
      invoice_number: '',
      phone: '',
    },
  },
  successFetchId: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        errorMessage: action.errorMessage,
        order: action.payload
      };
    case ORDER_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        orderId: action.payload,
        successFetchId: action.successFetchId,
      };
    default:
      return state;
  }
}
