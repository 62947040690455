import { EXPORT_DATA, LOADING, SEARCH_GLOBAL } from './constants';

const initialState = {
  loading: false,
  loadingExport: false,
  searchData: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXPORT_DATA:
      return {
        ...state,
        loadingExport: action.payload
      };
    case SEARCH_GLOBAL:
      return {
        ...state,
        searchData: action.payload
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}
