import axios from 'axios';

const API_URL = process.env.REACT_APP_API;

const onRequest = (config) => {
  const token = localStorage.getItem('token');
  config.headers['Authorization'] = `Bearer ${token}`;

  return config;
};

const onRequestError = (error) => {
  return Promise.reject(error);
};

const onResponse = (response) => {
  return response;
};

const onResponseError = async (error) => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 403) {
      const refresh_token = localStorage.getItem('refresh_token');

      try {
        const rs = await axios.post(`${API_URL}/user/refresh-token`, {
          refresh_token,
        }).catch(error => {
          if (error.response && error.response.data.msg[0] === 'token has expired') {
            localStorage.clear();
            location.reload();
          }
        });

        localStorage.setItem('token', rs.data.data.token);
        localStorage.setItem('refresh_token', rs.data.data.refresh_token);
        localStorage.setItem('isRefresh', 'true');
        location.reload();

        return;
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance) => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
