import React from 'react';
import { Button } from '../../components/elements';
import { ICONS } from '../../configs';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

const Error404 = ({ className }) => {
  return (
    <section className={[styles['not-found'], className].join(' ')}>
      <ICONS.FLOWER />
      <h1 className="text-center">We’ll get back to you</h1>
      <p className="text-center">Sorry can’t find anything, volutpat dui a id pellentesque ullamcorper magnis nunc lectus nisl. Nec, non leo bibendum ullamcorper mi sit id.</p>
      <Button className={styles['btn-refresh']}>Refresh</Button>
    </section>
  );
};

export default Error404;

Error404.defaultProps = {
  className: ''
};

Error404.propTypes = {
  className: PropTypes.string
};
