import React from 'react';
import Button from '../Button';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { ICONS } from '../../../configs';

const Modal = (props) => {
  const { size, onOk, haveButton, buttonTitle, childrenHeader, childrenBody, onClose } = props;

  const _renderSize = () => {
    if (size === 'small') {
      return styles['small'];
    } else if (size === 'large') {
      return styles['large'];
    } else {
      return styles['medium'];
    }
  };

  return (
    <div className={styles['modal-container']}>
      <div className={styles['modal-bg']} />
      <div className={[styles['modal-content'], _renderSize()].join(' ')}>
        <div className={`modal-header d-flex ${childrenHeader ? 'justify-content-between' : `justify-content-end ${styles['modal-header-custom']}`} `}>
          {childrenHeader}
          <ICONS.CLOSE className="cursor-pointer" onClick={onClose} />
        </div>
        <div className={styles['modal-body']}>
          {childrenBody ? childrenBody : <p>Modal</p>}
        </div>
        {haveButton && (
          <div className={styles['modal-footer']}>
            <Button children={buttonTitle} className={styles['modal-btn']} onClick={onOk} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;

Modal.defaultProps = {
  buttonTitle: '',
  childrenBody: null,
  childrenHeader: null,
  haveButton: false,
  onClose: null,
  onOk: null,
  size: 'medium'
};

Modal.propTypes = {
  buttonTitle: PropTypes.string,
  childrenBody: PropTypes.node,
  childrenHeader: PropTypes.node,
  haveButton: PropTypes.bool,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  size: PropTypes.string
};
