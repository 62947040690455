import { ADD_INBOUND, CATEGORY_DROPDOWN, FETCH_INBOUND, GET_PRODUCT_NAME, HUB_DROPDOWN, INBOUND_ID, PRODUCT_DROPDOWN, SUB_CATEGORY_DROPDOWN, SUPPLIER_DROPDOWN } from './constants';

const initialState = {
  categoryDropdown: [{
    id: '',
    name: '',
    parent_id: ''
  }],
  errorMessage: '',
  hubDropdown: [],
  inbound: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  inboundId: {
    cogs: 0,
    created_at: '',
    created_by: '',
    cut_off_date: '',
    delivery_note: '',
    expired_at: '',
    hub_id: 0,
    hub_name: '',
    id: 0,
    last_update_user_id: 0,
    position: '',
    price: 0,
    product_brand: '',
    product_id: 0,
    product_name: '',
    product_sku: 0,
    status: '',
    stock: 0,
    supplier_id: 0,
    supplier_name: '',
    updated_at: '',
    updated_by: '',
  },
  loading: false,
  productDropdown: [{
    bpom: 0,
    brand: '',
    cogs: 0,
    description: '',
    final_price: 0,
    id: 0,
    mui: '',
    name: '',
    size: 0,
    sku: 0,
    status: '',
    stock: 0,
    uom: '',
  }],
  productSku: {
    id: 0,
    name: ''
  },
  subCategoryDropdown: [{
    id: '',
    name: '',
    parent_id: ''
  }],
  success: false,
  supplierDropdown: [{
    id: '',
    name: '',
    parent_id: ''
  }],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INBOUND:
      return {
        ...state,
        errorMessage: action.errorMessage,
        inbound: action.payload
      };
    case INBOUND_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        inboundId: action.payload
      };
    case CATEGORY_DROPDOWN:
      return {
        ...state,
        categoryDropdown: action.payload,
        errorMessage: action.errorMessage,
      };
    case SUB_CATEGORY_DROPDOWN:
      return {
        ...state,
        errorMessage: action.errorMessage,
        subCategoryDropdown: action.payload,
      };
    case HUB_DROPDOWN:
      return {
        ...state,
        errorMessage: action.errorMessage,
        hubDropdown: action.payload,
      };
    case SUPPLIER_DROPDOWN:
      return {
        ...state,
        errorMessage: action.errorMessage,
        supplierDropdown: action.payload,
      };
    case PRODUCT_DROPDOWN:
      return {
        ...state,
        errorMessage: action.errorMessage,
        productDropdown: action.payload,
      };
    case ADD_INBOUND:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: action.loading,
        success: action.success
      };
    case GET_PRODUCT_NAME:
      return {
        ...state,
        errorMessage: action.errorMessage,
        productSku: action.payload
      };
    default:
      return state;
  }
}
