/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { ICONS } from '../../../configs';
import { toRupiah } from '../../../utils/others';

const InputText = (props) => {
  const [invisible, setInvisible] = useState(true);
  const passwordType = invisible ? 'password' : 'text';
  let pattern = '';

  if (props.type === 'email') {
    pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  } else if (props.type === 'tel') {
    pattern = '[0-9]*';
  } else if (props.type === 'dec') {
    pattern = '^\d*(\.\d{0,2})?$';
  }

  const onChange = (e) => {
    const target = {
      target: {
        name: props.name,
        value: props.type === 'rupiah' || props.type === 'int' ? (e.target.value).replace(/,|\D/g, '') : e.target.value
      }
    };

    if (props.type === 'tel') {
      if (e.target.validity.valid) {
        props.onChange(target);
      }
    } else {
      props.onChange(target);
    }
  };

  return (
    <div className={[props.errorResponse ? styles['input-text-error'] : styles['input-text'], props.outerClassName].join(' ')}>
      {props.label && <label className={styles['label']} htmlFor={props.name}>{props.label}</label>}
      <div className={styles['input-group']}>
        {
          props.append && (
            <div className={[styles['append'], props.appendCustom].join(' ')}>
              {props.append}
            </div>
          )
        }
        {
          props.type === 'textarea' ? (
            <textarea
              className={['form-control', styles['form-control-custom'], props.inputClassName].join(' ')}
              cols="30"
              disabled={props.disabled}
              id={props.name}
              name={props.name}
              onChange={onChange}
              placeholder={props.placeholder}
              rows="5"
              value={props.value}
            />
          ) : (
            <input
              className={['form-control', styles['form-control-custom'], props.inputClassName].join(' ')}
              disabled={props.disabled}
              id={props.name}
              name={props.name}
              onChange={onChange}
              onClick={props.onClick}
              pattern={pattern}
              placeholder={props.placeholder}
              readOnly={props.read}
              ref={props.wrapperRef}
              type={props.type === 'password' ? passwordType : props.type}
              value={props.type !== 'rupiah' ? props.value : (props.value === null || props.value === '' ? props.value : toRupiah(props.value || 0))}
            />
          )
        }
        {
          props.name === 'password' && (
            <div
              className={['cursor-pointer', styles['prepend']].join(' ')}
              onClick={() => setInvisible(!invisible)}>
              {invisible ? <ICONS.EYESSLASH /> : <ICONS.EYESACTIVE className={styles['icon-active']} />}
            </div>
          )
        }
      </div>
      {props.errorResponse && <span className={styles['error-helper']}>{props.errorResponse}</span>}
    </div>
  );
};

export default InputText;

InputText.defaultProps = {
  append: null,
  appendCustom: '',
  disabled: false,
  errorResponse: '',
  inputClassName: '',
  label: '',
  onChange: null,
  onClick: null,
  outerClassName: '',
  placeholder: 'Please type here...',
  read: false,
  type: '',
  value: '',
  wrapperRef: null,
};

InputText.propTypes = {
  append: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  appendCustom: PropTypes.string,
  disabled: PropTypes.bool,
  errorResponse: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  outerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  read: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  wrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
};
