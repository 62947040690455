import React from 'react';
import { Alert, Sidebar } from '../../elements';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

const DefaultLayouts = ({ children }) => {
  const { type, message } = useSelector((state) => state.alert);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-2 p-0">
          <Sidebar />
        </div>
        <div className={['col-10 p-0', styles['main-content']].join(' ')}>
          {children}
        </div>
      </div>
      {type !== 'hide' && <Alert message={message} type={type} />}
    </div>
  );
};

export default DefaultLayouts;

DefaultLayouts.propTypes = {
  children: PropTypes.element,
};

DefaultLayouts.defaultProps = {
  children: null,
};
