import React, { useState } from 'react';
import { Button, UploadImage } from '../../../elements';
import InputText from '../../../fields/InputText';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';
import { useShipment } from '../../../../pages/Shipment/actions';
import { useSelector } from 'react-redux';

const ModalAddDriver = ({ isDriver }) => {
  const [form, setForm] = useState({
    image: '',
    name: '',
    phoneNumber: '',
    plat_no: '',
    status: '',
    vehicle_type: '',
  });
  const disabled = !isDriver ? form.name === '' || form.phoneNumber === '' || form.plat_no === '' || form.vehicle_type === '' || form.image === '' : false;
  const { updateStatus } = useShipment();
  const { loading, shipmentId } = useSelector(state => state.shipment);

  const _onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const _submit = async () => {
    if (isDriver) {
      await updateStatus({
        driver_name: form.name,
        status: form.status,
      }, shipmentId.id, { by: 'created_at', hub: '', limit: '10', order: 'desc', page: 1, search: '', status: '' });
    }
  };

  return (
    <div className={[styles['modal-add-admin'], 'container-fluid'].join(' ')}>
      <div className="row">
        {isDriver ? (
          <>
            <InputText
              inputClassName={styles['input-add']}
              label="Name"
              name="name"
              onChange={_onChange}
              placeholder="name"
              value={form.name}
            />
            <InputText
              inputClassName={styles['input-add']}
              label="Status"
              name="status"
              onChange={_onChange}
              placeholder="Status"
              value={form.status}
            />
          </>
        ) : (
          <>
            <div className="col-6">
              <InputText
                inputClassName={styles['input-add']}
                label="Name"
                name="name"
                onChange={_onChange}
                placeholder="Name"
                value={form.name}
              />
            </div>
            <div className="col-6">
              <InputText
                inputClassName={styles['input-add']}
                label="No HP"
                name="phoneNumber"
                onChange={_onChange}
                placeholder="No HP"
                type="tel"
                value={form.phoneNumber}
              />
            </div>
            <div className="col-6">
              <InputText
                inputClassName={styles['input-add']}
                label="Vehicle Type"
                name="vehicle_type"
                onChange={_onChange}
                placeholder="Vehicle Type"
                value={form.vehicle_type}
              />
            </div>
            <div className="col-6">
              <InputText
                inputClassName={styles['input-add']}
                label="Plat No"
                name="plat_no"
                onChange={_onChange}
                placeholder="Plat No"
                type="tel"
                value={form.plat_no}
              />
            </div>
            <div className={styles['upload']}>
              <label className={styles['label']}>Driver Image</label>
              <UploadImage />
            </div>
          </>
        )}
        <Button children="Submit" className={styles[!disabled ? 'btn-submit' : 'btn-disabled']} isDisabled={disabled} isLoading={loading} onClick={_submit} />
      </div>
    </div>
  );
};

export default ModalAddDriver;

ModalAddDriver.defaultProps = {
  isDriver: false,
};

ModalAddDriver.propTypes = {
  isDriver: PropTypes.bool,
};
