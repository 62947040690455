import { FETCH_INVENTORY, INVENTORY_ID } from './constants';

const initialState = {
  errorMessage: '',
  inventory: {
    HasNext: false,
    Records: [],
    TotalData: 0,
    TotalPage: 0,
  },
  inventoryId: {
    aging_before_expire: '',
    aging_from_inbound: '',
    cogs: 0,
    created_at: '',
    created_by: 0,
    expired_at: '',
    hub_id: 0,
    hub_name: '',
    id: 0,
    inbound_at: '',
    last_update_user_id: 0,
    location: '',
    product_brand: '',
    product_id: 0,
    product_name: '',
    product_sku: 0,
    qty: 0,
    status: '',
    status_inbound: '',
    updated_at: '',
    updated_by: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INVENTORY:
      return {
        ...state,
        errorMessage: action.errorMessage,
        inventory: action.payload
      };
    case INVENTORY_ID:
      return {
        ...state,
        errorMessage: action.errorMessage,
        inventoryId: action.payload
      };
    default:
      return state;
  }
}
